<template>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="0"
      controls
      label-next=""
      label-prev=""
      background="#ababab"
      img-width="320"
      img-height="200"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="image in images" :key="image.id"
        :img-src="image.image ? image.image : image.original_url"
      >
      <p v-if="image.author">{{$t('author')}}: {{ image.author}}</p>
    </b-carousel-slide>
    </b-carousel>
</template>

<script>
  export default {
    props: {
    images: []
    },
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
  }
</script>

<style>
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px !important;
  width: 100px !important;
}
.carousel-indicators li {
      border-color: #e100ff;
      border-width: 12px;
      border-style: none solid none solid;
      background-color: #e100ff;
      height: 2px;
      margin: 0 16px;
      content: none;
      opacity: 1;
      padding: 1px;
      position: relative;
    }
    .carousel-indicators li::after {
      bottom: -7px;
      content: none;
      left: -7px;
      padding: 1px;
      position: absolute;
      right: -7px;
      top: -7px;
    }
    .carousel-indicators li.active {
      background-color: #7f00ff;
      border-color: #7f00ff;
      content: none;
      border-width: 12px;
      border-style: none solid none solid;
    }
    .carousel-caption{
      bottom: 0 !important;
      color: #000 !important;
      padding-bottom: 0 !important;
      font-weight: 700;
    }
</style>