export const messages = {
    en: {
      additional_names: 'Additional names',
      toxic: 'Toxic',
      safe: 'Safe',
      unknown: 'Unknown',
      check_is_plant_toxic: 'Check is plant toxic',
      plant_not_found: 'Plants not found',
      try_different_query: 'Try changing search criteria',
      author: 'Image author',
      email: 'Email',
      description: 'Description',
      report_comments_title: 'Submit your suggestions',
      send: 'Send',
      cancel: 'Cancel',
      report_agreement_description: 'I voluntarily consent to be contacted by Kevin Pietrzyk, ul. Lea 219/7, 30-133 Kraków, using telecommunications terminal devices and automatic calling systems to the e-mail address provided by me, in order to respond to the submitted request.',
      plant: 'Plant',
      report_missing_plant: "Can't find the plant you need? Let us know!",
      description_max_characters: 'Maximum 500 characters',
      error_email: 'Incorrect email',
      empty_description: 'Empty description',
      empty_agreement: 'Empty agreement',
      create_report_success: 'Report created!',
      create_report_error: 'Error during creating report',
      write_email: 'Write to us: kontakt@plants4pets.pl'
    },
    pl: {
      additional_names: 'Dodatkowe nazwy',
      toxic: 'Toksyczna',
      safe: 'Bezpieczna',
      unknown: 'Nie wiadomo',
      check_is_plant_toxic: 'Sprawdź toksyczność rośliny',
      plant_not_found: 'Nie znaleziono roślin',
      try_different_query: 'Spróbuj zmienić kryteria wyszukania',
      author: 'Autor zdjęcia',
      email: 'Email',
      description: 'Opis',
      report_comments_title: 'Zgłoś swoje uwagi',
      send: 'Wyślij',
      cancel: 'Anuluj',
      report_agreement_description: 'Wyrażam dobrowolnie zgodę na kontaktowanie się ze mną przez Kevin Pietrzyk, ul. Lea 219/7, 30-133 Kraków, przy użyciu telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących na podany przeze mnie adres poczty elektronicznej, w celu odpowiedzi na zadane zgłoszenie.',
      plant: 'Roślina',
      report_missing_plant: 'Nie ma szukanej przez Ciebie rośliny? Zgłoś nam!',
      description_max_characters: 'Maksymalnie 500 znaków',
      error_email: 'Niepoprawny email',
      empty_description: 'Pusty opis',
      empty_agreement: 'Nie przyjęta zgoda',
      create_report_success: 'Uwaga zgłoszona!',
      create_report_error: 'Bład przy tworzeniu uwagi',
      write_email: 'Napisz do nas maila: kontakt@plants4pets.pl'
    }
  }
