<template>
  <div v-if="plants.length">
    <PlantCard class="m-4" v-for="plant in plants" :key="plant.id"
      :plant="plant"
    ></PlantCard>
  </div>
  <div v-else>
    <b-card :title="$t('plant_not_found')" class="m-4">
      <b-card-text>{{ $t('try_different_query') }}</b-card-text>
      <b-card-text>{{ $t('report_missing_plant') }}
        <BIconChatRightFill v-b-modal.plant-modal animation="false" @click="selectPlant(null)" class="top-icon-hover" variant="primary" />
      </b-card-text>
      <b-card-text>{{ $t('write_email') }}</b-card-text>
    </b-card>
  </div>
</template>

<script>
import PlantCard from './PlantCard.vue'
import { usePlantStore } from '@/stores/plant-store'
import { mapActions } from 'pinia'
import { BIconChatRightFill } from 'bootstrap-vue'


export default {
  components: {
    PlantCard,
    BIconChatRightFill,
  },
  data() {
    return {
    }
  },
  beforeMount() {
    this.getPlants()
  },
  setup(){
    const store = usePlantStore();
    store.getPlants()
  },
  computed: {
    plants: {
        get () { 
          const store = usePlantStore();
          // if(store.is_loved_plants){
          //   return store.plants.filter(plant => store.loved_plant_ids.includes(plant.id ))
          // }
          return store.plants
        },
    },
  },
  methods: {
    ...mapActions(usePlantStore, ['getPlants']),
    selectPlant(plant){
      const store = usePlantStore();
      store.selected_plant = plant
    },
  }
}
</script>