<template>
  <b-card bg-variant="light" class="form-card">
    <b-form-group
      id="fieldset-1"
      content-cols-sm
    >
    <b-form-input class="input-element" id="input-1" @update="debouncedGetPlants" :placeholder="$t('check_is_plant_toxic')" v-model="q" trim></b-form-input>
    <b-row>
      <b-col cols="8">
      <b-form-checkbox-group
        v-model="selected_animals"
        :options="animals"
        name="pets"
        value-field="id"
        :text-field="'name_' + lang"
        inline
        class="d-flex mb-2 ml-4"
        @change="setSelectedAnimals"
      ></b-form-checkbox-group>
    </b-col>
    <b-col cols="4">
      <b-form-checkbox
        id="loved-plants-checkbox"
        v-model="is_loved_plants"
        @change="debouncedGetPlants"
        name="loved-plants"
        class="d-flex icon-float"
      >
        <BIconHeartFill variant="danger" />
      </b-form-checkbox>
      <b-form-checkbox
        id="loved-plants-checkbox"
        v-model="is_safe"
        @change="debouncedGetPlants"
        name="loved-plants"
        class="d-flex icon-float"
      >
        <BIconCheckCircleFill variant="success" />
      </b-form-checkbox>
    </b-col>
    </b-row>
    </b-form-group>
  </b-card>
</template>

<script>

import { usePlantStore } from '@/stores/plant-store'
import { mapState } from 'pinia'
import { debounce } from '@/utils'
import { BIconHeartFill, BIconCheckCircleFill } from 'bootstrap-vue'

export default {
  data() {
    return {
    }
  },
  components: {
    BIconHeartFill,
    BIconCheckCircleFill
  },
  computed: {
    ...mapState(usePlantStore, ['animals']),
    ...mapState(usePlantStore, ['lang']),
    q: {
        get () { 
          const store = usePlantStore();
          return store.q
        },
        set (value) {
            const store = usePlantStore();
            store.q = value
            localStorage.setItem("q", value)
          }
    },
    selected_animals: {
        get () { 
          const store = usePlantStore();
          return store.selected_animals
        },
        set (value) {
            const store = usePlantStore();
            store.selected_animals = value
            localStorage.setItem("selected_animals", JSON.stringify(value))
            if(store.is_safe){
              this.debouncedGetPlants()
            }
          }
    },
    is_loved_plants: {
        get () { 
          const store = usePlantStore();
          return store.is_loved_plants
        },
        set (value) {
            const store = usePlantStore();
            store.is_loved_plants = value
            localStorage.setItem("is_loved_plants", value)
          }
    },
    is_safe: {
        get () { 
          const store = usePlantStore();
          return store.is_safe
        },
        set (value) {
            const store = usePlantStore();
            store.is_safe = value
            localStorage.setItem("is_safe", value)
          }
    }
  },
  setup(){
      const store = usePlantStore();
      store.getAnimals()
  },
  created(){
     this.debouncedGetPlants = debounce(()=>{
        const store = usePlantStore();
        store.current_page = 1
        // store.rows = 0
        localStorage.setItem("current_page", 1)
        store.getPlants();
     }, 1000);
  },
  methods: {
    setSelectedAnimals(new_selected_animals){
      const store = usePlantStore();
      store.selected_animals = new_selected_animals;
      localStorage.setItem("selected_animals", JSON.stringify(new_selected_animals))
    }
  }
}
</script>

<style>
.custom-control-inline{
  margin-left: 12px;
}
.custom-control-label{
  margin-left: 3px;
}
.form-card .card-body{
  padding-top: 0px !important
}
.input-element{
  margin-bottom: 8px !important;
}
.icon-float{
  margin-left: 8px;
  float: right
}
</style>