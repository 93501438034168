<template>
  <div>
    <b-card :title="plant.scientific_name + ' / ' + getLangValue(plant, 'name')">
      <a v-if="plant.shopping_link" :href="plant.shopping_link"  target="_blank"><BIconCart class="top-icon top-icon-hover" variant="primary" /></a>
      <a :href="plant_link" target="blank">
        <BIconImage class="top-icon top-icon-hover"></BIconImage>
      </a>
      <BIconHeartFill v-if="isLovedPlant(plant)" @click="removeLovedPlant(plant)" class="top-icon top-icon-hover" variant="danger" />
      <BIconHeart v-else class="top-icon top-icon-hover" @click="addLovedPlant(plant)" variant="danger" />
      <BIconShieldCheck v-if="plant.is_verified" class="top-icon" variant="warning" />
      <BIconChatRightFill v-b-modal.plant-modal animation="false" @click="selectPlant(plant)" class="top-icon top-icon-hover" variant="primary" />
      <PlantImageCarousel :images="plant.plantimage_set"></PlantImageCarousel>
      <b-card-text>
        <b>{{$t('additional_names')}}: </b>
        <span>{{ getLangValue(plant, 'additional_names', false) }}</span>
      </b-card-text>
      <b-list-group flush>
        <div class="mb-1" v-for="animal in plant.plantanimal_set" :key="animal.id">
          <b-list-group-item v-if="selected_animals.includes(animal.animal.id)" show :variant="getToxicClass(animal.is_toxic)" >
            <b>{{ animalName(animal) }}:</b> {{ $t(getToxicName(animal.is_toxic)) }} <BIconCheckCircleFill v-if="animal.is_toxic == -1" variant="success" />
          </b-list-group-item>
        </div>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import PlantImageCarousel from './PlantImageCarousel.vue';
import { usePlantStore } from '@/stores/plant-store'
import { mapState } from 'pinia'
import { BIconCart, BIconHeart, BIconHeartFill,BIconShieldCheck, BIconChatRightFill, BIconCheckCircleFill, BIconImage } from 'bootstrap-vue'

export default {
  components: {
    PlantImageCarousel,
    BIconCart,
    BIconHeart,
    BIconHeartFill,
    BIconShieldCheck,
    BIconChatRightFill,
    BIconCheckCircleFill,
    BIconImage
  },
  props: {
    plant: []
  },
  computed: {
    ...mapState(usePlantStore, ['selected_animals']),
    ...mapState(usePlantStore, ['lang']),
    plant_link(){
      return `https://www.google.com/search?q=` + encodeURIComponent("images of " + this.plant.scientific_name)
    }
  },
  methods: {
    selectPlant(plant){
      const store = usePlantStore();
      store.selected_plant = plant
    },
    animalName(plantanimal){
      const store = usePlantStore();
      const nameKey = 'name_' + store.lang;
      if(nameKey in plantanimal.animal && plantanimal.animal[nameKey]){
        return plantanimal.animal[nameKey];
      }
      return plantanimal.animal.name;
    },
    getLangValue(obj, field, use_default=true){
      const store = usePlantStore();
      const nameKey = field + '_' + store.lang;
      if(nameKey in obj && obj[nameKey]){
        return obj[nameKey];
      }
      if(use_default){
        return obj[field];
      }
      return '-';
    },
    getToxicClass(is_toxic){
      if(is_toxic == 1){
        return 'danger'
      }
      if(is_toxic == -1){
        return 'success'
      }
      return 'secondary'
    },
    getToxicName(is_toxic){
      if(is_toxic == 1){
        return 'toxic'
      }
      if(is_toxic == -1){
        return 'safe'
      }
      return 'unknown'
    },
    isLovedPlant(plant){
      const store = usePlantStore();
      if(store.loved_plant_ids.includes(plant.id)){
        return true;
      }
      return false;
    },
    addLovedPlant(plant){
      const store = usePlantStore();
      store.loved_plant_ids.push(plant.id)
      store.loved_plant_ids =  store.loved_plant_ids.filter((value, index, array) => array.indexOf(value) === index);
      localStorage.setItem('loved_plant_ids', JSON.stringify(store.loved_plant_ids))
    },
    removeLovedPlant(plant){
      const store = usePlantStore();
      store.loved_plant_ids =  store.loved_plant_ids.filter((value, index, array) => array.indexOf(value) === index);
      // const index = store.loved_plant_ids.indexOf(plant.id);
      store.loved_plant_ids = store.loved_plant_ids.filter(i => i != plant.id)
      localStorage.setItem('loved_plant_ids', JSON.stringify(store.loved_plant_ids))
      if(store.is_loved_plants){
        // const plant_index = store.plants.indexOf(i => i.id = plant.id);
        store.plants = store.plants.filter(i => i.id != plant.id);
      }
    },
  }
}
</script>

<style>
.top-icon{
  margin: 2px;
}
.top-icon-hover:hover {
  opacity: 0.5;
}
</style>