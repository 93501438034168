<template>
  <b-navbar type="dark" variant="dark">
    <b-navbar-brand class="footer-header">Plants 4 Pets</b-navbar-brand>
  </b-navbar>
</template>

<script>

export default {
}
</script>

<style>
.footer-header {
    float: left;
    text-align: center;
    width: 100%;
    margin-right: 0 !important;
}
</style>