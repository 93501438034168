import Vue from 'vue'
import App from './App.vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueI18n from 'vue-i18n'
import { messages } from './translations'


import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files ( ensure to follow the order)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.use(PiniaVuePlugin)
Vue.use(BootstrapVue)
Vue.use(VueI18n)

const pinia = createPinia()

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || 'pl',
  fallbackLocale: 'en',
  messages
})


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  pinia,
  i18n
}).$mount('#app')