import { defineStore } from 'pinia'

const URL = window.location.href === 'http://localhost:8080/' ? 'http://localhost:8000/' : 'https://pubwatch.pl/'
export const PAGE_SIZE = 10

export const usePlantStore = defineStore('plant-store', {
    state: () => (
        { plants: [],
          selected_plant: [],
          animals: [],
          selected_animals: JSON.parse(localStorage.getItem("selected_animals")) || [],
          lang: localStorage.getItem("lang") || 'pl',
          langs: ['en', 'pl'],
          q: localStorage.getItem("q") || '',
          loved_plant_ids: JSON.parse(localStorage.getItem("loved_plant_ids")) || [],
          is_loved_plants: JSON.parse(localStorage.getItem("is_loved_plants")) || false,
          is_safe: JSON.parse(localStorage.getItem("is_safe")) || false,
          rows: 0,
          current_page: localStorage.getItem("current_page") || 1
        }),
    getters: {
      
    },
    actions: {
        async getPlants() {
            let query = `q=${this.q}&page=${this.current_page}`
            if(this.is_loved_plants){
                query += `&ids=${this.loved_plant_ids.join(',')}`
            }
            if(this.is_safe){
                query += `&is_toxic=-1`
                query += `&animal_ids=${this.selected_animals.join(',')}`
            }
            fetch(`${URL}plants/api/plants?${query}`)
            .then(response => response.json())
            .then(data => {
                this.plants = data.results
                this.rows = data.count
                window.scrollTo({
                    top: 0,
                    behavior: 'instant'
                  })
            })
        },
        async getAnimals() {
            fetch(`${URL}plants/api/animals`)
            .then(response => response.json())
            .then(data => {
                this.animals = data.results
                if(localStorage.getItem("selected_animals") === null){
                    this.selected_animals =  data.results.map((val) => val.id)
                }
            })
        },
        async postReport(data) {
            return fetch(`${URL}plants/api/plant_reports`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                     "Content-Type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.status)
        }
    }
  })