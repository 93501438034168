<template>
   <b-modal 
   id="plant-modal" 
   :hide-header-close=true
   :ok-title="$t('send')"
   :title="$t('report_comments_title')"
   @ok="onSubmit"
   @cancel="onReset">
    <b-alert v-if="show_message" :variant="message_variant" show>{{ $t(message) }}</b-alert>
    <div v-if="selected_plant">{{$t('plant')}}<br/><b>{{ selected_plant.scientific_name }} / {{ selected_plant.name_pl }}</b></div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-email"
        label-for="input-email"
        :label="$t('email')"
      >
        <b-form-input
          id="input-email"
          v-model="form.email"
          type="email"
          :placeholder="$t('email')"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-description" :label="$t('description')" label-for="input-description">
        <b-form-textarea
          id="input-description"
          type="textarea"
          v-model="form.description"
          :placeholder="$t('description_max_characters')"
          required
        ></b-form-textarea>
      </b-form-group>

      <b-form-checkbox
      id="checkbox-agreement"
      v-model="form.agreement"
      name="checkbox-agreement"
      >{{$t('report_agreement_description') }}
      </b-form-checkbox>
    </b-form>
  </b-modal> 
</template>

<script>
import { usePlantStore } from '@/stores/plant-store'
import { mapState } from 'pinia'

export default {
  props: {
  },
  data() {
      return {
        form: {
          email: '',
          description: '',
          agreement: false,
        },
        show: true,
        show_message: false,
        message_variant: '',
        message: ''
      }
  },
  computed: {
    ...mapState(usePlantStore, ['selected_plant']),
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      this.message_variant = 'danger'
      let is_error = false
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const is_email =  re.test(this.form.email);
       if(!is_email){
        this.message = 'error_email'
        is_error = true
      }
      if(!this.form.description){
        this.message = 'empty_description'
        is_error = true
      }
      if(!this.form.agreement){
        this.message = 'empty_agreement'
        is_error = true
      }
      if(!is_error){
        let data = this.form
        data.plant = this.selected_plant ? this.selected_plant.id : null
        const store = usePlantStore();
        const status = await store.postReport(data)
        if(status == 201){
          this.message = 'create_report_success'
          this.message_variant = 'success'
          this.form.email = ''
          this.form.description = ''
          this.form.agreement = null
        }else {
          this.message = 'create_report_error'
        }
      }

      this.show_message = true
    },
    onReset() {
      this.form.email = ''
      this.form.description = ''
      this.form.agreement = null
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.show_message = false,
      this.message_variant = '',
      this.message = ''
      
      const store = usePlantStore();
      store.selected_plant = null
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>
<style>
  .custom-control-label{
    display: inline !important
  }
</style>