<template>
  <b-navbar type="dark" variant="dark" sticky>
    <b-navbar-brand  class="navbar-header" @click="scrollToTop">Plants 4 Pets</b-navbar-brand>
    <b-navbar-nav class="lang-nav ml-auto">
        <b-nav-item-dropdown class="navbar-lang" :text="lang.toUpperCase()">
            <b-dropdown-item @click="setLang(lang_option); $root.$i18n.locale = lang_option"  v-for="lang_option in langs" :key="lang_option">{{lang_option.toUpperCase()}}</b-dropdown-item>
        </b-nav-item-dropdown>   
    </b-navbar-nav>
  </b-navbar>
</template>

<script>

import { usePlantStore } from '@/stores/plant-store'
import { mapState } from 'pinia'

export default {
  methods: { 
    scrollToTop() {
      window.scrollTo(0,0);
    },
    setLang(value) {
      const store = usePlantStore();
      store.lang = value
      localStorage.setItem("lang", value)
    }
  },
  computed: {
    
    ...mapState(usePlantStore, ['langs']),
    lang: {
        get () { 
          const store = usePlantStore();
          return store.lang
        }
    }
  },
}
</script>

<style>
.dropdown-menu {
    min-width: 0rem !important;
}
.dropdown-item {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  
}

.lang-nav{
  right: 0;
  min-width: 50px !important;
}

.navbar-lang {
    float: right;
    text-align: center;
    min-width: 0!;
}
.navbar-header {
    float: left;
    text-align: center;
    width: calc(100%);
    padding-left: 50px ;
    margin-right: 0 !important;
}
</style>