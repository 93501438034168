<template>
  <b-container  class="custom-container" id="app">
    <b-row class="top-row text-center" align-v="center">
      <PlantNavbar />
      <PlantForm />
      <PlantPagination />
      <PlantCards />
      <PlantModal />
   </b-row>
   <b-row :class="'text-center ' + (plants.length ? '' : 'bottom-row')" align-v="center">
      <PlantPagination />
      <PlantFooter />
   </b-row>
  </b-container>
</template>

<script>
import PlantCards from './components/PlantCards.vue'
import PlantForm from './components/PlantForm.vue'
import PlantNavbar from './components/PlantNavbar.vue';
import PlantFooter from './components/PlantFooter.vue';
import PlantPagination from './components/PlantPagination.vue';

import { usePlantStore } from '@/stores/plant-store'
import { mapState } from 'pinia'
import PlantModal from './components/PlantModal.vue';

export default {
  name: 'App',
  components: {
    PlantNavbar,
    PlantForm,
    PlantCards,
    PlantFooter,
    PlantPagination,
    PlantModal
  },
  computed: {
    ...mapState(usePlantStore, ['plants'])
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 720px;
  
}
body, #app{
  min-height: 100vh;
}
.top-row{
}
.bottom-row{
  position: fixed;
  bottom: 0;  
  width: min(100%, 720px);
}
</style>
