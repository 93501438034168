<template>
  <div>
    <b-pagination v-if="rows"
      align="center"
      class="custom-pagination"
      v-model="current_page"
      pills
      :total-rows="rows"
      :per-page="page_size">
    </b-pagination>
  </div>
</template>

<script>

import { usePlantStore, PAGE_SIZE } from '@/stores/plant-store'
import { mapState } from 'pinia'

export default {
  computed: {
    page_size(){
      return PAGE_SIZE
    },
    ...mapState(usePlantStore, ['rows']),
    current_page: {
        get () { 
          const store = usePlantStore();
          return store.current_page
        },
        set (value) {
            
            const store = usePlantStore();
            store.current_page = value
            localStorage.setItem("current_page", value)
            store.getPlants()
          }
    }
  }
}
</script>

<style>
.custom-pagination {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
}
</style>